import { graphql, navigate } from 'gatsby';
import { ENABLE_MEMBERSHIP } from 'gatsby-env-variables';
import { useTranslation } from 'gatsby-plugin-react-i18next';
import { ReactElement, useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

// UI Kit & Components
import ShowGovInsuranceModalContent from 'display-components/membership/gov-insurance/modals';
import Stepper from 'display-components/stepper/stepper';

import { BirdiModalHeaderWithBackgroundColor } from 'components/birdi-modal/birdi-modal-header';
import WorkflowLayout from 'components/layouts/workflow/workflow.layout';
import MembershipGovInsuranceForm from 'components/membership-gov-insurance-form';
import WorkflowFooterLinks from 'components/workflow-footer-links/workflow-footer-links';
import WorkflowLayoutFormWrapper from 'components/workflow-layout-form-wrapper/workflow-layout-form-wrapper.component';

import { closeModal, openModal } from 'state/birdi-modal/birdi-modal.reducers';
import { setMembershipFormValues } from 'state/membership-registration/membership-registration.reducer';
// States
import { membershipRegistrationFormValuesSelector } from 'state/membership-registration/membership-registration.selectors';

import { GovInsuranceFormValues } from 'types/membership';

// Hooks
import useMembershipRegistrationInit from 'hooks/useMembershipRegistrationInit';
import usePageRedirection from 'hooks/usePageRedirection';

import './index.style.scss';

const GovInsurance = ({
    location,
    data
}: {
    location: any;
    data: GatsbyTypes.MembershipGovInsuranceDataQuery;
}): ReactElement => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const formValues = useSelector(membershipRegistrationFormValuesSelector);
    const { onInit } = useMembershipRegistrationInit();

    // membership redirection
    const hasMembershipRedirection = usePageRedirection({
        featureFlag: ENABLE_MEMBERSHIP
    });

    if (hasMembershipRedirection) {
        return <></>;
    }

    // Footer links selector
    const workflowFooterLinks =
        data.allBlockContentRegistrationFlowFooterLinks?.edges?.[0]?.node?.field_registration_footer_link ?? [];

    const showGovInsuranceModal = useCallback(() => {
        dispatch(
            openModal({
                showClose: false,
                type: 'danger',
                size: 'xl',
                isModalCentered: true,
                onClose: () => {
                    dispatch(closeModal({}));
                },
                headerContent: (
                    <BirdiModalHeaderWithBackgroundColor
                        headerText={t('modals.membership.govInsurance.warning.title')}
                        type="danger"
                        icon="alert"
                    />
                ),
                bodyContent: (
                    <ShowGovInsuranceModalContent
                        translation={t}
                        onContinueClick={() => {
                            dispatch(closeModal({}));
                        }}
                    />
                ),
                ctas: [
                    {
                        label: t('modals.membership.govInsurance.warning.ctaContinue'),
                        variant: 'primary',
                        onClick: () => {
                            dispatch(closeModal({}));
                        },
                        dataGALocation: 'MembershipGovInsuranceWarning'
                    }
                ],
                className: 'membership-gov-insured'
            })
        );
    }, [dispatch, t]);

    const onSubmit = useCallback(
        (values: Partial<GovInsuranceFormValues>) => {
            if (values?.enrollment === 'Yes') {
                showGovInsuranceModal();
            } else {
                dispatch(
                    setMembershipFormValues({
                        ...formValues,
                        enrollment: values?.enrollment
                    })
                );
                navigate('/get-started/personal-details');
            }

            // customer.io: track the insurance value
            _cio.track('has_gov_insurance', { insured: values?.enrollment === 'Yes' ? 1 : 0 });
        },
        [showGovInsuranceModal, dispatch, formValues]
    );

    useEffect(() => {
        const hasValidUserInfo = onInit();
        if (!hasValidUserInfo) {
            navigate('/get-started/membership');
        } else {
            _cio.page(window.location.href);
        }
    }, [formValues, onInit]);

    return (
        <WorkflowLayout
            className="membership-get-started-gov-uninsured"
            backgroundImage={data.backgroundImage}
            useRoundedCorners={false}
            metaData={{ nodeTitle: t('membership.govInsurance.eyebrowText') }}
            anonymousOnly={true}
        >
            <WorkflowLayoutFormWrapper
                currentFlow={'membership-registration'}
                eyebrowText={t('membership.govInsurance.eyebrowText')}
                title={t('membership.govInsurance.profileTitle')}
            >
                <MembershipGovInsuranceForm
                    initialValues={
                        formValues?.enrollment ? { enrollment: formValues.enrollment } : { enrollment: undefined }
                    }
                    onSubmit={onSubmit}
                />
                <Stepper activeStep={1} steps={['1', '2', '3', '4']} />
            </WorkflowLayoutFormWrapper>

            {/* Workflow links */}
            <WorkflowFooterLinks links={workflowFooterLinks as any} locationGAflow="membership" />
        </WorkflowLayout>
    );
};

export default GovInsurance;

export const query = graphql`
    query MembershipGovInsuranceData($language: String!) {
        locales: allLocale(filter: { language: { eq: $language } }) {
            edges {
                node {
                    ns
                    data
                    language
                }
            }
        }
        backgroundImage: file(relativePath: { eq: "assets/images/white-feathers-background.jpg" }) {
            id
            childImageSharp {
                gatsbyImageData(formats: [AUTO])
            }
        }
        allBlockContentRegistrationFlowFooterLinks(
            filter: { field_registration_flow_types: { eq: "membership_flow" } }
        ) {
            edges {
                node {
                    field_registration_footer_link {
                        title
                        uri
                    }
                }
            }
        }
    }
`;
